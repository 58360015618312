.opaqueBorderAfter::after, .section__content::after, .sectionSix::after {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: " ";
  height: 15px;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0; }

@media (min-width: 768px) {
  .opaqueBorderAfter::after, .section__content::after, .sectionSix::after {
    height: 57px; } }

.opaqueBorderBefore::before, .sectionSix::before {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: " ";
  height: 15px;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0; }

@media (min-width: 768px) {
  .opaqueBorderBefore::before, .sectionSix::before {
    height: 57px; } }

.visibilityHidden {
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
  margin: -1px;
  height: 1px;
  padding: 0;
  width: 1px;
  border: 0; }

.bgGradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,070707+100&0.75+0,0.75+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(238, 238, 238, 0.75) 0%, rgba(7, 7, 7, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfeeeeee', endColorstr='#bf070707',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.plus_icon {
  background-image: url("../../../assets/images/icons/page-link-plus-white-icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: inline-block;
  margin: 0 0 -7px 5px;
  position: relative;
  height: 32px;
  width: 29px; }
  .plus_icon.black {
    background-image: url("../../../assets/images/icons/page-link-plus-black-icon.svg"); }

.header__nav__toggle {
  background: #fff; }

.sectionOne {
  background: url("../../../assets/images/home/mmhh-home-main.jpg") center center no-repeat;
  background-size: 100% auto;
  background-size: cover;
  position: relative;
  width: 100%; }

.section__content {
  height: 100%;
  padding: 0;
  z-index: 1; }
  .section__content .section__content__image {
    display: none; }
    @media (min-width: 768px) {
      .section__content .section__content__image {
        margin: -155px 0 0 -111px;
        position: absolute;
        display: block;
        left: 50%;
        top: 50%; } }
    @media (min-width: 1024px) {
      .section__content .section__content__image {
        margin-top: -114px; } }
  .section__content .section__title {
    background: url("../../../assets/images/halfHexOutline.svg") no-repeat center left;
    margin-bottom: -92px;
    padding: 0 0 0 70px;
    position: absolute;
    min-height: 170px;
    color: #fff;
    width: 230px;
    bottom: 50%;
    left: 0; }
    .section__content .section__title a {
      text-decoration: none; }
    @media (min-width: 768px) {
      .section__content .section__title {
        background-size: 90px auto;
        padding: 0 0 0 121px;
        margin-bottom: 0;
        bottom: 170px;
        width: 300px; }
        .section__content .section__title .plus_icon {
          margin: 0 0 -5px 5px; } }
    @media (min-width: 1024px) {
      .section__content .section__title {
        background-size: 112px auto;
        padding: 0 0 0 145px;
        margin: -117px 0 0 0;
        position: absolute;
        bottom: auto;
        width: 320px;
        top: 50%;
        left: 0; } }

.sectionTwo {
  background-color: #F2F2F2;
  position: relative;
  padding: 20px 0; }
  .sectionTwo .section__title {
    background: url("../../../assets/images/home/home page-section2-hexagon.png") no-repeat center left;
    background-size: 58px 129px;
    position: relative;
    min-height: 175px;
    color: #000;
    bottom: auto;
    width: 270px;
    margin: 0; }
  .sectionTwo .section__content::after {
    display: none; }
  .page__homepage .sectionTwo .sectionTwo__list {
    text-align: center;
    margin: 30px auto 0; }
  .page__homepage .sectionTwo .sectionTwo__list__item {
    display: inline-block;
    margin: 0 10px;
    width: auto; }
  .page__homepage .sectionTwo .sectionTwo__list__image {
    height: 78px;
    width: 78px; }
  .page__homepage .sectionTwo .image_masked, .page__homepage .sectionTwo .image_mask {
    height: 78px;
    width: 78px; }
  @media (min-width: 768px) {
    .sectionTwo {
      padding: 30px 0; }
      .page__homepage .sectionTwo .sectionTwo__list {
        margin: 50px auto 25px; }
      .page__homepage .sectionTwo .section__title {
        background-size: 104px auto;
        width: 490px; }
      .page__homepage .sectionTwo .sectionTwo__list__item,
      .page__homepage .sectionTwo .sectionTwo__list__image {
        height: 232px;
        width: 208px; }
      .page__homepage .sectionTwo .image_masked, .page__homepage .sectionTwo .image_mask {
        height: 208px;
        width: auto; } }
  @media (min-width: 1024px) {
    .page__homepage .sectionTwo .section__title {
      width: 450px; }
    .page__homepage .sectionTwo .section__title,
    .page__homepage .sectionTwo .sectionTwo__list {
      display: inline-block; }
    .page__homepage .sectionTwo .sectionTwo__list {
      padding-right: 10px;
      float: right; }
    .page__homepage .sectionTwo .sectionTwo__list__item,
    .page__homepage .sectionTwo .sectionTwo__list__image {
      height: 189px;
      width: 165px; }
    .page__homepage .sectionTwo .image_masked, .page__homepage .sectionTwo .image_mask {
      height: 165px;
      width: auto; } }
  @media (min-width: 1280px) {
    .sectionTwo {
      padding-bottom: 45px; }
      .page__homepage .sectionTwo .sectionTwo__list {
        padding-right: 10px;
        margin: 0; }
      .page__homepage .sectionTwo .sectionTwo__list__item,
      .page__homepage .sectionTwo .sectionTwo__list__image {
        height: 272px;
        width: 248px; }
      .page__homepage .sectionTwo .image_masked, .page__homepage .sectionTwo .image_mask {
        height: 250px;
        width: auto; } }

.sectionThree {
  background: url("../../../assets/images/mmhh-pattern-white-outline.png") #DCDCDC repeat;
  padding: 40px 0 10px;
  position: relative;
  width: 100%; }
  .sectionThree .card {
    opacity: 0; }
  @media (min-width: 768px) {
    .sectionThree {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .sectionThree .card {
        height: auto;
        width: 45%; }
        .sectionThree .card a {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
      .sectionThree .card__title {
        line-height: 0.6; }
      .sectionThree .card__description {
        height: auto; } }
  @media (min-width: 1024px) {
    .sectionThree .card {
      width: 30%; } }

.sectionFour {
  padding: 20px 0 30px;
  position: relative;
  overflow: hidden; }
  .sectionFour .section__four__bgImage {
    position: absolute;
    height: 159px;
    width: 67px;
    top: 30px;
    right: 0; }
  .sectionFour .section__title {
    margin-right: 80px;
    text-align: right;
    float: right;
    width: 170px; }
  .sectionFour .section__subtitle {
    display: inline-block;
    padding-right: 30px;
    position: relative;
    margin-right: 50px;
    float: right;
    clear: both;
    width: auto; }
    .sectionFour .section__subtitle a {
      text-decoration: none;
      padding-right: 30px;
      color: inherit;
      z-index: 1; }
      .sectionFour .section__subtitle a:hover {
        text-decoration: underline; }
    .sectionFour .section__subtitle::after {
      background: url("../../../assets/images/icons/page-link-plus-black-icon.svg") no-repeat center center;
      position: absolute;
      height: 27px;
      width: 24px;
      content: '';
      z-index: -1;
      bottom: 0;
      right: 30px; }
  .sectionFour .cool__brands__list {
    padding: 0 10px;
    display: block;
    clear: both; }
  .sectionFour .cool__brands__list_item {
    text-align: center;
    margin: 0 auto;
    height: auto;
    width: 250px; }
    .sectionFour .cool__brands__list_item a {
      position: relative;
      display: block; }
    .sectionFour .cool__brands__list_item .tooltip {
      display: none !important;
      position: absolute;
      cursor: pointer;
      height: 20px;
      width: 20px; }
      .sectionFour .cool__brands__list_item .tooltip img {
        display: none !important;
        vertical-align: top;
        line-height: 0;
        height: 20px;
        width: 18px; }
    .sectionFour .cool__brands__list_item img {
      height: auto;
      width: 100%;
      z-index: 0; }
    .sectionFour .cool__brands__list_item:nth-child(1n) {
      margin-right: 0; }
      .sectionFour .cool__brands__list_item:nth-child(1n) .tooltip {
        right: 120px;
        bottom: 0; }
    .sectionFour .cool__brands__list_item:nth-child(2n) {
      margin-left: 0; }
      .sectionFour .cool__brands__list_item:nth-child(2n) .tooltip {
        left: 80px;
        top: 30px; }
    .sectionFour .cool__brands__list_item:nth-child(3n) {
      margin-right: 0; }
      .sectionFour .cool__brands__list_item:nth-child(3n) .tooltip {
        right: 120px;
        bottom: 20px; }
  @media (min-width: 768px) {
    .sectionFour {
      padding: 20px 0 50px; }
      .sectionFour::after {
        display: block;
        content: ' ';
        clear: both;
        width: 100%; }
      .sectionFour .section__title {
        margin-bottom: 0.25em;
        margin-right: 140px;
        width: 300px; }
      .sectionFour .section__subtitle {
        margin-right: 110px; }
      .sectionFour .section__four__bgImage {
        height: 252px;
        width: 113px; }
      .sectionFour .cool__brands__list {
        margin-top: -270px;
        padding-left: 20px;
        width: 100%;
        float: left; }
      .sectionFour .cool__brands__list_item:nth-child(1n) {
        float: left; }
        .sectionFour .cool__brands__list_item:nth-child(1n) .tooltip {
          right: 60px;
          bottom: 0; }
      .sectionFour .cool__brands__list_item:nth-child(2n) {
        margin-left: 80px;
        clear: left;
        float: left; }
        .sectionFour .cool__brands__list_item:nth-child(2n) .tooltip {
          right: auto;
          bottom: 80px;
          left: -20px;
          top: auto; }
      .sectionFour .cool__brands__list_item:nth-child(3n) {
        margin-left: 40px;
        margin-top: 50px;
        float: left; }
        .sectionFour .cool__brands__list_item:nth-child(3n) .tooltip {
          bottom: 20px;
          right: auto;
          left: 65px; } }
  @media (min-width: 1024px) {
    .sectionFour .cool__brands__list_item:nth-child(1n) {
      margin-top: 70px; }
    .sectionFour .cool__brands__list_item:nth-child(2n) {
      margin-left: 50px;
      margin-top: 0px;
      clear: none;
      float: left; }
      .sectionFour .cool__brands__list_item:nth-child(2n) .tooltip {
        right: auto;
        bottom: 80px;
        left: -20px;
        top: auto; }
    .sectionFour .cool__brands__list_item:nth-child(3n) {
      margin-left: 150px;
      margin-top: 1px;
      float: left;
      clear: left; }
      .sectionFour .cool__brands__list_item:nth-child(3n) .tooltip {
        bottom: 20px;
        right: auto;
        left: 65px; } }
  @media (min-width: 1280px) {
    .sectionFour .section__four__bgImage {
      top: 85px; }
    .sectionFour .section__title {
      margin-top: 85px; }
    .sectionFour .cool__brands__list {
      margin-top: -310px; }
    .sectionFour .cool__brands__list_item {
      display: inline-block; }
      .sectionFour .cool__brands__list_item:nth-child(2n) {
        margin-top: -50px; }
      .sectionFour .cool__brands__list_item:nth-child(3n) {
        margin-left: 50px;
        margin-top: 30px;
        clear: none;
        float: left; } }

.tooltipsy {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  font-family: inherit;
  text-shadow: none;
  max-width: 200px;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 2px; }

.sectionFive {
  background-color: #F2F2F2;
  padding: 25px 0; }
  .sectionFive .section__sub__title {
    position: relative;
    padding: 30px 75px;
    text-align: center;
    margin: 0 auto;
    width: 100%; }
    .sectionFive .section__sub__title br {
      display: none; }
    .sectionFive .section__sub__title::before, .sectionFive .section__sub__title::after {
      position: absolute;
      margin-top: -65px;
      height: 130px;
      width: 58px;
      content: '';
      top: 50%; }
    .sectionFive .section__sub__title::before {
      background: url("../../../assets/images/home/home-page-section-5-left-hexagon.png") no-repeat center center;
      background-size: auto 100%;
      left: 0; }
    .sectionFive .section__sub__title::after {
      background: url("../../../assets/images/home/home-page-section-5-right-hexagon.png") no-repeat center center;
      background-size: auto 100%;
      right: 0; }
  .sectionFive .section__title {
    text-align: center;
    padding: 0px 10px;
    max-width: 300px;
    margin: 0 auto; }
  @media (min-width: 768px) {
    .sectionFive .section__sub__title {
      padding: 30px 195px;
      margin-top: 3%; }
      .sectionFive .section__sub__title::before, .sectionFive .section__sub__title::after {
        margin-top: -130px;
        height: 250px;
        width: 111px; }
    .sectionFive .section__title {
      max-width: 760px; } }
  @media (min-width: 1024px) {
    .sectionFive .section__sub__title {
      padding: 0 130px 50px;
      margin-top: 1%; }
      .sectionFive .section__sub__title::before, .sectionFive .section__sub__title::after {
        margin-top: -85px;
        height: 250px;
        width: 111px; }
      .sectionFive .section__sub__title br {
        display: block; } }

.sectionSix {
  position: relative;
  line-height: 0; }
  .sectionSix img {
    height: auto;
    width: 100%; }

.sectionSeven {
  background-color: #F2F2F2;
  position: relative;
  padding: 25px 0; }
  .sectionSeven .section__title {
    background: url("../../../assets/images/whosbeentalking.png") no-repeat center left;
    background-size: 58px 129px;
    padding: 0 0 0 75px;
    width: 295px; }
  @media (min-width: 600px) {
    .sectionSeven .section__title {
      display: inline-block;
      vertical-align: middle; } }
  @media (min-width: 768px) {
    .sectionSeven .section__title {
      background-size: 104px auto;
      padding: 0 0 0 150px;
      padding-top: 20px;
      height: 232px;
      width: 450px; } }
  @media (min-width: 1024px) {
    .sectionSeven .section__title {
      margin-bottom: 0; } }

.newsletter {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 650ms ease-in-out;
  transition: all 650ms ease-in-out;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
  min-height: 100%;
  overflow: hidden; }
  .newsletter .newsletter__content__wrapper {
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 950ms ease-in-out;
    transition: all 950ms ease-in-out;
    margin: -150px 0 0 -150px;
    visibility: hidden;
    position: absolute;
    width: 300px;
    z-index: 10;
    opacity: 0;
    left: 50%;
    top: 0%; }
  .newsletter.showNewsLetter {
    background: rgba(0, 0, 0, 0.45);
    visibility: visible;
    opacity: 1; }
    .newsletter.showNewsLetter .newsletter__content__wrapper {
      visibility: visible;
      opacity: 1;
      top: 50%; }
  .newsletter .card {
    width: 100%; }
    .newsletter .card .card__title {
      line-height: inherit;
      margin-bottom: 20px;
      text-align: center;
      color: #000;
      height: auto; }
    .newsletter .card .card__description {
      background: url("../../../assets/images/mmhh-pattern-white-outline.png") #DCDCDC repeat; }
      .newsletter .card .card__description p:last-child {
        margin: 0px auto 0; }
    .newsletter .card form {
      margin: 0 auto; }
  .newsletter form button {
    background-color: #BADBDB; }
  .newsletter .close_popup {
    -webkit-text-decoration: center;
            text-decoration: center;
    display: inline-block;
    position: relative;
    width: 100%; }
    .newsletter .close_popup:hover {
      text-decoration: underline; }
  .newsletter .plus_icon {
    background: url("../../../assets/images/icons/close-icon.svg") no-repeat center center;
    display: inline-block;
    position: relative;
    margin: 0 0 -10px 0;
    height: 31px;
    width: 28px; }
  @media (min-width: 768px) {
    .newsletter .newsletter__content__wrapper {
      margin-left: -180px;
      width: 360px; }
      .newsletter .newsletter__content__wrapper .card .card__title {
        text-align: center;
        width: 100%; }
      .newsletter .newsletter__content__wrapper .card .card__description p:last-child {
        margin: 10px auto 0; } }
